import produce from 'immer'
import { combineReducers } from 'redux'
import {
  POST_ADD_SUCCESS,
  POST_DELETE_SUCCESS,
  POST_FETCH_FAILURE,
  POST_FETCH_START,
  POST_FETCH_SUCCESS,
  POST_UPDATE_SUCCESS,
} from '../action/constants'

const initialState = {
  byIds: {},
  posts: {
    page: 0,
    loading: false,
    paging: true,
    data: [],
  },
}

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case POST_FETCH_SUCCESS:
      action.payload.payload.forEach((postItem) => {
        draft[postItem._id] = postItem
      })
      break
    case POST_ADD_SUCCESS:
      draft[action.payload._id] = { ...action.payload, totalLikes: 0 }
      break
    case POST_DELETE_SUCCESS:
      delete draft[action.payload]
      break
    case POST_UPDATE_SUCCESS:
      let oldObj = draft[action.payload._id]
      draft[action.payload._id] = {
        ...action.payload,
        user: oldObj.user,
        totalLikes: oldObj.totalLikes,
      }
      break
  }
}, initialState.byIds)

const postSubReducer = produce((draft, action) => {
  switch (action.type) {
    case POST_FETCH_START:
      draft.loading = true
      break
    case POST_FETCH_SUCCESS:
      let newPost = action.payload.payload

      if (newPost.length < 15) {
        draft.paging = false
      }
      newPost = newPost.map((postItem) => postItem._id)
      draft.loading = false
      draft.data.push(...newPost)
      draft.page = draft.page + 1
      break
    case POST_FETCH_FAILURE:
      draft.loading = false
      draft.paging = false
      break
    case POST_DELETE_SUCCESS:
      const index = draft.data.indexOf(action.payload)
      draft.data.splice(index, 1)
      break
    case POST_ADD_SUCCESS:
      draft.data.unshift(action.payload._id)
  }
}, initialState.posts)

const postReducer = combineReducers({
  byIds: idsReducer,
  posts: postSubReducer,
})

export default postReducer
