import React, { Suspense, lazy } from 'react'
import Feedback from './views/Feedback/Feedback'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'

const Users = React.lazy(() => import('./views/Users/Users'))
const UsersSearch = React.lazy(() => import('./views/Users/UsersSearch'))
const UserDailyStats = React.lazy(() => import('./views/Users/UserDailyStats'))

const Posts = React.lazy(() => import('./views/Posts/Posts'))
const PostCreateForm = React.lazy(() => import('./views/Posts/PostCreateForm'))

const Polls = React.lazy(() => import('./views/Polls/Polls'))
const PollCreateForm = React.lazy(() => import('./views/Polls/PollCreateForm'))
const PollResult = React.lazy(() => import('./views/Polls/PollResult'))
const SuggestedPoll = React.lazy(() => import('./views/Polls/SuggestedPolls/SuggestedPoll'))

const Shows = React.lazy(() => import('./views/Awards/Show/Show'))
const Awards = React.lazy(() => import('./views/Awards/Award/Awards'))
const AwardsByCategory = React.lazy(() => import('./views/Awards/Category/AwardsByCategory'))
const AwardCategory = React.lazy(() => import('./views/Awards/Category/AwardCategory'))
const AwardSubCategory = React.lazy(() => import('./views/Awards/Category/AwardSubCategory'))
const AwardCreateForm = React.lazy(() => import('./views/Awards/Award/AwardCreateForm'))
const ShowCreateForm = React.lazy(() => import('./views/Awards/Show/ShowCreateForm'))
const AwardResult = React.lazy(() => import('./views/Awards/Award/AwardResult'))
const AwardCategoryEdit = React.lazy(() => import('./views/Awards/Category/AwardCategoryEdit'))

const EditorNews = React.lazy(() => import('./views/News/EditorNews/EditorNews'))
const EditorNewsEditForm = React.lazy(() => import('./views/News/EditorNews/EditorNewsEditForm'))
const News = React.lazy(() => import('./views/News/News'))
const NewsCreateForm = React.lazy(() => import('./views/News/NewsCreateForm'))
const NewsSearch = React.lazy(() => import('./views/News/Search/NewsSearch'))

const EditorPetition = React.lazy(() => import('./views/Petition/EditorPetition/EditorPetition'))
const EditorPetitionEditForm = React.lazy(() =>
  import('./views/Petition/EditorPetition/EditorPetitionEditForm'),
)
const Petition = React.lazy(() => import('./views/Petition/petition'))
const PetitionCreateForm = React.lazy(() => import('./views/Petition/PetitionCreateForm'))
const PetitionSearch = React.lazy(() => import('./views/Petition/search/PetitionSearch'))

const Videos = React.lazy(() => import('./views/Videos/Videos'))
const VideosCreateForm = React.lazy(() => import('./views/Videos/VideosCreateForm'))

const Channels = React.lazy(() => import('./views/Channels/Channels'))
const ChannelCreateForm = React.lazy(() => import('./views/Channels/ChannelCreateForm'))

const Quizes = React.lazy(() => import('./views/Quizes/Quizes'))
const QuizCreateForm = React.lazy(() => import('./views/Quizes/QuizCreateForm'))
const QuestionCreateForm = React.lazy(() => import('./views/Quizes/QuestionCreateForm'))

const Wallet = React.lazy(() => import('./views/Wallet/Wallet'))

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const EditorNewsCreateForm = React.lazy(() =>
  import('./views/News/EditorNews/EditorNewsCreateForm'),
)
const CustomAudio = React.lazy(() => import('./views/News/CustomAudio'))

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  return (
    <Suspense fallback={<div>Loading....</div>}>
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DefaultLayout />,
      children: [
        { element: <Navigate to="/users" replace /> },
        { path: 'users', name: 'Users', element: <Users /> },
        { path: 'users/search', name: 'Users', element: <UsersSearch /> },
        { path: 'users/dailyStats', name: 'Users Daily Stats', element: <UserDailyStats /> },
        // Posts
        { path: 'posts', name: 'Posts', element: <Posts /> },
        { path: 'posts/add', name: 'Post Add Form', element: <PostCreateForm /> },
        {
          path: 'posts/:id/edit',
          name: 'Post Edit Form',
          element: <PostCreateForm editMode={true} />,
        },
        // Polls
        { path: 'polls', name: 'Polls', element: <Polls /> },
        { path: 'polls/add', name: 'Poll Add Form', element: <PollCreateForm /> },
        { path: 'polls/suggested', name: 'Suggested Polls', element: <SuggestedPoll /> },
        {
          path: 'polls/:id/edit',
          name: 'Poll Edit Form',
          element: <PollCreateForm editMode={true} />,
        },
        { path: 'polls/:id/result', name: 'Poll Results', element: <PollResult /> },
        // Awards
        { path: 'shows', name: 'Award Polls', element: <Shows /> },
        { path: 'allAwards/:showId', name: 'Award Polls', element: <Awards /> },
        {
          path: 'show/edit/:showId',
          name: 'Show Edit',
          element: <ShowCreateForm editMode={true} />,
        },
        {
          path: 'allAwards/:cat/:showId/:categoryId',
          name: 'Award Polls',
          element: <AwardsByCategory />,
        },
        {
          path: 'award-category/edit/:showId/:catId',
          exact: true,
          name: 'Award Category Edit',
          element: <AwardCategoryEdit />,
        },
        { path: 'award-category/:showId', name: 'Award Category', element: <AwardCategory /> },
        { path: 'awards/:showId/add', name: 'Award Add Form', element: <AwardCreateForm /> },
        {
          path: 'awards/:showId/:categoryId/add',
          name: 'Award Add Form',
          element: <AwardCreateForm />,
        },
        { path: 'shows/add', name: 'Show Add Form', element: <ShowCreateForm /> },
        { path: 'shows/add-sub-category', name: 'Show Add Form', element: <AwardSubCategory /> },
        {
          path: 'awards/:id/edit',
          name: 'Award Edit Form',
          element: <AwardCreateForm editMode={true} />,
        },
        { path: 'awards/:id/result', name: 'Award Results', element: <AwardResult /> },
        // Editor News
        { path: 'editor/news', name: 'Editor News', element: <EditorNews /> },
        { path: 'editor/news/:id/edit', name: 'Editor News Edit', element: <EditorNewsEditForm /> },
        // News
        { path: 'news', name: 'News', element: <News /> },
        { path: 'news/add', name: 'News Add Form', element: <NewsCreateForm /> },
        { path: 'news/search', name: 'News Search', element: <NewsSearch /> },
        { path: 'news/:id/edit', name: 'News Edit Form', element: <NewsCreateForm /> },
        //editr petition
        { path: 'editor/petition', name: 'Editor Petition', element: <EditorPetition /> },
        {
          path: 'editor/petition/:id/edit',
          name: 'Editor Petition Edit',
          element: <EditorPetitionEditForm />,
        },
        //petiton
        { path: 'petition', name: 'Petition', element: <Petition /> },
        {
          path: 'petition/:id/edit',
          name: 'Petition Edit Form',
          element: <PetitionCreateForm editMode={true} />,
        },
        { path: 'petition/add', name: 'Petition Add Form', element: <PetitionCreateForm /> },
        { path: 'petition/search', name: 'Petition Search', element: <PetitionSearch /> },
        // Videos
        { path: 'videos', name: 'Videos', element: <Videos /> },
        { path: 'videos/add', name: 'Videos Add Form', element: <VideosCreateForm /> },
        {
          path: 'videos/:id/edit',
          name: 'Videos Edit Form',
          element: <VideosCreateForm editMode={true} />,
        },
        // SuggestedPoll
        { path: 'feedback', name: 'Feedback', element: <Feedback /> },
        // Others

        { path: 'channels', name: 'Channels', element: <Channels /> },
        { path: 'channels/add', name: 'Channels Create Form', element: <ChannelCreateForm /> },
        {
          path: 'channels/:id',
          name: 'Channels Edit Form',
          element: <ChannelCreateForm editMode={true} />,
        },

        { path: 'quizes', name: 'Quizes', element: <Quizes /> },
        { path: 'quizes/add', name: 'Quiz Add Form', element: <QuizCreateForm /> },
        { path: 'quizes/addQuestion', name: 'Question Add Form', element: <QuestionCreateForm /> },
        {
          path: 'quizes/question/edit/:id',
          name: 'Question Update Form',
          element: <QuestionCreateForm editMode={true} />,
        },
        {
          path: '/quizes/:id/edit',
          name: 'Quiz Edit Form',
          element: <QuizCreateForm editMode={true} />,
        },
        { path: 'quizes/:id/result', name: 'Quiz Results', element: <PollResult /> },
        { path: '/wallet', name: 'Wallet', element: <Wallet /> },
      ],
    },
    { path: 'login', element: <Login /> },
    { path: 'RplzqHHpEofW8jjqtb3L', element: <EditorNewsCreateForm /> },
    { path: 'JercNjmdeyNKpnLKVEXW', element: <CustomAudio /> },
    { path: '404', element: <Page404 /> },
    { path: '500', element: <Page500 /> },
  ])
}

export const routes = [
  { path: '/', exact: true, name: 'Home' },

  { path: '/users', exact: true, name: 'Users', component: Users },
  { path: '/users/search', exact: true, name: 'Users', component: UsersSearch },
  { path: '/users/dailyStats', exact: true, name: 'Users Daily Stats', component: UserDailyStats },
  // Posts
  { path: '/posts', exact: true, name: 'Posts', component: Posts },
  { path: '/posts/add', exact: true, name: 'Post Add Form', component: PostCreateForm },
  { path: '/posts/:id/edit', exact: true, name: 'Post Edit Form', component: PostCreateForm },
  // Polls
  { path: '/polls', exact: true, name: 'Polls', component: Polls },
  { path: '/polls/add', exact: true, name: 'Poll Add Form', component: PollCreateForm },
  { path: '/polls/suggested', exact: true, name: 'Suggested Polls', component: SuggestedPoll },
  { path: '/polls/:id/edit', exact: true, name: 'Poll Edit Form', component: PollCreateForm },
  { path: '/polls/:id/result', exact: true, name: 'Poll Results', component: PollResult },
  // Awards
  { path: '/shows', exact: true, name: 'Award Polls', component: Shows },
  { path: '/allAwards/:showId', exact: true, name: 'Award Polls', component: Awards },
  { path: '/show/edit/:showId', exact: true, name: 'Show Edit', component: ShowCreateForm },
  {
    path: '/allAwards/:cat/:showId/:categoryId',
    exact: true,
    name: 'Award Polls',
    component: AwardsByCategory,
  },
  {
    path: '/award-category/:showId',
    exact: true,
    name: 'Award Category',
    component: AwardCategory,
  },
  {
    path: '/award-category/edit/:showId/:catId',
    exact: true,
    name: 'Award Category Edit',
    component: AwardCategoryEdit,
  },
  { path: '/awards/:showId/add', exact: true, name: 'Award Add Form', component: AwardCreateForm },
  { path: '/shows/add', exact: true, name: 'Show Add Form', component: ShowCreateForm },
  {
    path: '/shows/add-sub-category',
    exact: true,
    name: 'Show Add Form',
    component: AwardSubCategory,
  },
  { path: '/awards/:id/edit', exact: true, name: 'Award Edit Form', component: AwardSubCategory },
  { path: '/awards/:id/result', exact: true, name: 'Award Results', component: AwardResult },
  // Editor News
  { path: '/editor/news', exact: true, name: 'Editor News', component: EditorNews },
  {
    path: '/editor/news/:id/edit',
    exact: true,
    name: 'Editor News Edit',
    component: EditorNewsEditForm,
  },
  // News
  { path: '/news', exact: true, name: 'News', component: News },
  { path: '/news/add', exact: true, name: 'News Add Form', component: NewsCreateForm },
  { path: '/news/search', exact: true, name: 'News Search', component: NewsSearch },
  { path: '/news/:id/edit', exact: true, name: 'News Edit Form', component: NewsCreateForm },
  // Videos
  { path: '/videos', exact: true, name: 'Videos', component: Videos },
  { path: '/videos/add', exact: true, name: 'Videos Add Form', component: VideosCreateForm },
  { path: '/videos/:id/edit', exact: true, name: 'Videos Edit Form', component: VideosCreateForm },
  // SuggestedPoll
  { path: '/feedback', exact: true, name: 'Feedback', component: Feedback },
  // Others

  { path: '/channels', exact: true, name: 'Channels', component: Channels },
  {
    path: '/channels/add',
    exact: true,
    name: 'Channels Create Form',
    component: ChannelCreateForm,
  },
  { path: '/channels/:id', exact: true, name: 'Channels Edit Form', component: ChannelCreateForm },

  { path: '/quizes', exact: true, name: 'Quizes', component: Quizes },
  { path: '/quizes/add', exact: true, name: 'Quiz Add Form', component: QuizCreateForm },
  {
    path: '/quizes/addQuestion',
    exact: true,
    name: 'Question Add Form',
    component: QuestionCreateForm,
  },
  {
    path: '/quizes/question/edit/:id',
    exact: true,
    name: 'Question Update Form',
    component: QuestionCreateForm,
  },
  // {path: '/quizes/:id/edit', exact: true, name: 'Quiz Edit Form', component: PollEditForm},
  { path: '/quizes/:id/result', exact: true, name: 'Quiz Results', component: PollResult },
  { path: '/wallet', exact: true, name: 'Wallet', component: Wallet },
]
