import produce from 'immer'
import { combineReducers } from 'redux'
import {
  EDITOR_NEWS_FETCH_FAILURE,
  EDITOR_NEWS_FETCH_START,
  EDITOR_NEWS_FETCH_SUCCESS,
  EDITOR_NEWS_LANGUAGE_SWITCH,
  EDITOR_NEWS_REMOVE_FROM_LIST,
  EDITOR_NEWS_UPDATE_SUCCESS,
} from '../action/constants'

const initialState = {
  byIds: {},
  news: {
    created: {
      loading: false,
      paging: true,
      data: [],
      hindi: false,
    },
    approved: {
      loading: false,
      paging: true,
      data: [],
      hindi: false,
    },
    declined: {
      loading: false,
      paging: true,
      data: [],
      hindi: false,
    },
  },
}

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case EDITOR_NEWS_FETCH_SUCCESS:
      action.payload.data.forEach((newsItem) => {
        draft[newsItem._id] = newsItem
      })
      break
    case EDITOR_NEWS_UPDATE_SUCCESS:
      draft[action.payload._id] = action.payload
      break
  }
}, initialState.byIds)

const newsSubReducer = produce((draft, action) => {
  const type = action.payload && action.payload.type
  let data = action.payload && action.payload.data

  switch (action.type) {
    case EDITOR_NEWS_FETCH_START:
      draft[type].loading = true
      break
    case EDITOR_NEWS_LANGUAGE_SWITCH:
      draft[type].hindi = !draft[type].hindi
      draft[type].data = []
      break
    case EDITOR_NEWS_FETCH_SUCCESS:
      data = data.map((newsItem) => newsItem._id)
      draft[type].loading = false
      draft[type].data.push(...data)
      draft.paging = false
      break
    case EDITOR_NEWS_FETCH_FAILURE:
      draft[type].loading = false
      draft[type].paging = false
      break
    case EDITOR_NEWS_REMOVE_FROM_LIST:
      const index = draft['created'].data.indexOf(action.payload)
      if (index > -1) {
        draft['created'].data.splice(index, 1)
      }
      break
  }
}, initialState.news)

const editorNewsReducer = combineReducers({
  byIds: idsReducer,
  news: newsSubReducer,
})

export default editorNewsReducer
