import {combineReducers} from "redux";
import adminReducer from './adminReducer';
import newsReducer from './newsReducer';
import pollReducer from "./pollReducer";
import pollResultReducer from "./pollResultReducer";
import postReducer from "./postReducer";
import editorNewsReducer from "./editorNewsReducer";
import channelsReducer from './channelReducer';
import usersReducer from './userReducer';
import awardResultReducer from './awardResultReducer';
import awardsReducer from './awardsReducer';
import regionsReducer from './regionReducer';
import videosReducer from "./videoReducer";
import showsReducer from "./awardShowsReducer";
import quizReducer from "./quizReducer";
import quizCategoriesReducer from './quizCategoriesReducer';
import quizQuestionsReducer from './QuizQuestionsReducer';
import petitionReducer from './petitionReducer'
import {persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import editorPetitionReducer from './editorPetitionReducer'

const config ={
  key:'root',
  storage:storageSession,
  whitelist:['quiz','quiz_cat','quiz_ques','shows'],

}

const rootReducer =  combineReducers({
  channels: channelsReducer,
  admin: adminReducer,
  news: newsReducer,
  editorNews: editorNewsReducer,
  poll: pollReducer,
  awards: awardsReducer,
  awardResults: awardResultReducer,
  petition: petitionReducer,
  editorPetition:editorPetitionReducer,
  post: postReducer,
  pollResults: pollResultReducer,
  users: usersReducer,
  regions: regionsReducer,
  video: videosReducer,
  quiz:quizReducer,
  quiz_cat:quizCategoriesReducer,
  quiz_ques:quizQuestionsReducer,
  shows: showsReducer
  
});

export default persistReducer(config,rootReducer)

