/* eslint-disable default-case */
import produce from 'immer'
import { combineReducers } from 'redux'
import {
  PETITION_ADD_SUCCESS,
  PETITION_DELETE_SUCCESS,
  PETITION_FETCH_FAILURE,
  PETITION_FETCH_START,
  PETITION_FETCH_SUCCESS,
  PETITION_SINGLE_FETCH_SUCCESS,
  PETITION_UPDATE_SUCCESS,
} from '../action/constants'

const initialState = {
  byIds: {},
  petition: {
    loading: false,
    data: [],
    totalPages: 0,
  },
}

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case PETITION_FETCH_SUCCESS:
      action.payload.payload.forEach((petitionItem) => {
        draft[petitionItem._id] = petitionItem
      })
      break
    case PETITION_ADD_SUCCESS:
      draft[action.payload._id] = action.payload
      break
    case PETITION_DELETE_SUCCESS:
      delete draft[action.payload]
      break
    case PETITION_UPDATE_SUCCESS:
      let oldObj = draft[action.payload._id]

      draft[action.payload._id] = { ...action.payload, user: oldObj?.user }
      break
    case PETITION_SINGLE_FETCH_SUCCESS:
      let petitionItem = action.payload
      draft[petitionItem._id] = petitionItem
      break
  }
}, initialState.byIds)

const petitionSubReducer = produce((draft, action) => {
  switch (action.type) {
    case PETITION_FETCH_START:
      draft.loading = true
      break
    case PETITION_FETCH_SUCCESS:
      console.log(draft,action.payload)
      let newPetitions = action.payload.payload
      newPetitions = newPetitions.map((petitionItem) => petitionItem._id)
      draft.loading = false
      draft.data = newPetitions
      draft.totalPages = Math.ceil(action.payload.currCount / action.payload.maxCount)
      break
    case PETITION_FETCH_FAILURE:
      draft.loading = false
      draft.paging = false
      draft.paging = false
      break
    case PETITION_ADD_SUCCESS:
      draft.data.unshift(action.payload._id)
      break
    case PETITION_DELETE_SUCCESS:
      const index = draft.data.indexOf(action.payload)
      draft.data.splice(index, 1)
      break
  }
}, initialState.petition)

const petitionReducer = combineReducers({
  byIds: idsReducer,
  petition: petitionSubReducer,
})

export default petitionReducer
