import produce from 'immer'
import { combineReducers } from 'redux'
import {
  EDITOR_PETITION_FETCH_FAILURE,
  EDITOR_PETITION_FETCH_START,
  EDITOR_PETITION_FETCH_SUCCESS,
  EDITOR_PETITION_REMOVE_FROM_LIST,
  EDITOR_PETITION_UPDATE_SUCCESS,
} from '../action/constants'

const initialState = {
  byIds: {},
  petition: {
    created: {
      loading: false,
      paging: true,
      data: [],
      hindi: false,
    },
    approved: {
      loading: false,
      paging: true,
      data: [],
      hindi: false,
    },
    rejected: {
      loading: false,
      paging: true,
      data: [],
      hindi: false,
    },
  },
}

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case EDITOR_PETITION_FETCH_SUCCESS:
      action.payload.data.forEach((petitionItem) => {
        draft[petitionItem._id] = petitionItem
      })
      break
    case EDITOR_PETITION_UPDATE_SUCCESS:
      draft[action.payload._id] = action.payload
      break
  }
}, initialState.byIds)

const petitionSubReducer = produce((draft, action) => {
  const type = action.payload && action.payload.type
  let data = action.payload && action.payload.data

  switch (action.type) {
    case EDITOR_PETITION_FETCH_START:
      draft[type].loading = true
      break
    case EDITOR_PETITION_FETCH_SUCCESS:
      data = data.map((petitionItem) => petitionItem._id)
      draft[type].loading = false
      draft[type].data = data
      draft.paging = false
      break
    case EDITOR_PETITION_FETCH_FAILURE:
      draft[type].loading = false
      draft[type].paging = false
      break
    case EDITOR_PETITION_REMOVE_FROM_LIST:
      const index = draft['created'].data.indexOf(action.payload)
      if (index > -1) {
        draft['created'].data.splice(index, 1)
      }
      break
  }
}, initialState.petition)

const editorNewsReducer = combineReducers({
  byIds: idsReducer,
  petition: petitionSubReducer,
})

export default editorNewsReducer
