import produce from "immer";
import {combineReducers} from "redux";
import {
  POLL_ADD_SUCCESS,
  POLL_FETCH_FAILURE,
  POLL_FETCH_START,
  POLL_FETCH_SUCCESS,
  POLL_PAGE_FETCH_SUCCESS,
  POLL_UPDATE_SUCCESS
} from "../action/constants";

const initialState = {
  byIds: {},
  polls: {
    lastTime: undefined,
    loading: false,
    paging: true,
    data: [],
    pagedData: [],
  },
  paged:{
    count: undefined,
    maxCount: undefined,
    pageCount: undefined,
    polls :{}
  }
};

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case POLL_FETCH_SUCCESS:
      action.payload.payload.forEach(pollItem => {
        draft[pollItem._id] = pollItem;
      });
      break;
    case POLL_ADD_SUCCESS:
      draft[action.payload._id] = action.payload;
      break;
    case POLL_UPDATE_SUCCESS:
      let oldObj = draft[action.payload._id];
      draft[action.payload._id] = {...action.payload, user: oldObj.user};
      break;
  }
}, initialState.byIds);

const pollSubReducer = produce((draft, action) => {
  switch (action.type) {
    case POLL_FETCH_START:
      draft.loading = true;
      break;
    case POLL_FETCH_SUCCESS:
      console.info(action.payload);
      let newNews = action.payload.payload;
      if (newNews.length < 15) {
        draft.paging = false;
      }
      newNews = newNews.map(pollItem => pollItem._id);
      draft.loading = false;
      draft.data.push(...newNews);
      draft.lastTime = action.payload.lastTime || draft.lastTime;
      break;
    case POLL_FETCH_FAILURE:
      draft.loading = false;
      draft.paging = false;
      break;
    case POLL_ADD_SUCCESS:
      draft.data.unshift(action.payload._id);
  }
}, initialState.polls);

const pagedReducer = produce((draft, action) => {
  switch (action.type) {
    case POLL_PAGE_FETCH_SUCCESS:
      const page = action.payload?.page ?? -1
      if (page === -1) {
        return
      }
      draft.count = draft.count ?? action.payload.currCount
      draft.maxCount = draft.maxCount ?? action.payload.maxCount
      draft.pageCount = draft.pageCount ?? Math.ceil(draft.count / draft.maxCount)
      draft.polls[page] = action.payload.payload
      break
    case POLL_ADD_SUCCESS:
      draft.polls[0].unshift(action.payload)
  }
}, initialState.paged)

const pollReducer = combineReducers({
  byIds: idsReducer,
  polls: pollSubReducer,
  paged: pagedReducer
});

export default pollReducer;
