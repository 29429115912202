import {
  SHOW_FETCH_START,
  SHOW_FETCH_SUCCESS,
  SHOW_FETCH_FAILURE,
  SHOW_ADD_START,
  SHOW_ADD_FAILURE,
  SHOW_ADD_SUCCESS,
} from '../action/constants'
import { produce } from 'immer'

const INITIAL_STATE = {
  byIds: {},
  loading: false,
}

const showsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_FETCH_START:
      return produce(state, (draft) => {
        draft.loading = true
      })

    case SHOW_FETCH_SUCCESS:
      return produce(state, (draft) => {
        //
        draft.loading = false
        draft.byIds = action.payload
        // action.payload.forEach(show => {
        //     //
        //     draft.byIds = show
        //     draft.byIds[show._id] = show;
        // });
      })
    case SHOW_FETCH_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false
      })

    case SHOW_ADD_START:
      return produce(state, (draft) => {
        draft.loading = true
      })

    case SHOW_ADD_SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false
        draft.byIds[action.payload._id] = action.payload
      })

    case SHOW_ADD_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false
      })

    default:
      return state
  }
}

export default showsReducer
