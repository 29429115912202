import produce from "immer";
import {combineReducers} from "redux";
import {
  AWARD_ADD_SUCCESS,
  AWARD_FETCH_FAILURE,
  AWARD_FETCH_START,
  AWARD_FETCH_SUCCESS,
  AWARD_UPDATE_SUCCESS
} from "../action/constants";

const initialState = {
  byIds: {},
  awards: {
    page: 0,
    loading: false,
    paging: true,
    data: [],
  }
};

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case AWARD_FETCH_SUCCESS:
      action.payload.forEach(awardItem => {
        draft[awardItem._id] = awardItem;
      });
      break;
    case AWARD_ADD_SUCCESS:
      draft[action.payload._id] = action.payload;
      break;
    case AWARD_UPDATE_SUCCESS:
      let oldObj = draft[action.payload._id];
      draft[action.payload._id] = {...action.payload, user: oldObj.user};
      break;
  }
}, initialState.byIds);

const awardSubReducer = produce((draft, action) => {
  switch (action.type) {
    case AWARD_FETCH_START:
      draft.loading = true;
      break;
    case AWARD_FETCH_SUCCESS:
      let newAwards = action.payload;
      if (newAwards.length < 15) {
        draft.paging = false;
      }
      newAwards = newAwards.map(awardItem => awardItem._id);
      draft.loading = false;
      draft.data.push(...newAwards);
      draft.page = draft.page + 1;
      break;
    case AWARD_FETCH_FAILURE:
      draft.loading = false;
      draft.paging = false;
      break;
    case AWARD_ADD_SUCCESS:
      draft.data.unshift(action.payload._id);
  }
}, initialState.awards);

const awardsReducer = combineReducers({
  byIds: idsReducer,
  awards: awardSubReducer,
});

export default awardsReducer;
