import React, { useState, useEffect } from 'react'
import api from '../../api'
import { Button, Modal, Table, Tag } from 'antd'
import moment from 'moment'

const Feedback = () => {
  const columns = [
    { title: 'ID', dataIndex: '_id', key: '_id' },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      width: '50%',
    },
    {
      title: 'Type',
      dataIndex: 'deactivation',
      key: 'deactivation',
      render: (text) => {
        return text === true ? (
          <Tag color="red">De-Activate</Tag>
        ) : (
          <Tag color="green">Feedback</Tag>
        )
      },
    },
    {
      title: 'Created By',
      dataIndex: 'user.firstName',
      key: 'user',
      render: (text, record, index) => {
        return (
          <div
            onClick={() => {
              setCurrentUser(index)
              setVisible(true)
            }}
          >
            {`${record.user.firstName} ${record.user.lastName}`}
          </div>
        )
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: (text, row) => {
        return moment(new Date(text)).format('DD/MM/YYYY')
      },
    },
  ]
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [paging, setPaging] = useState(true)
  const [visible, setVisible] = useState(false)
  const [currentUser, setCurrentUser] = useState({})
  useEffect(() => {
    setLoading(true)
    const feedbackFetch = async () => {
      await api.get('/feedback').then((response) => {
        if (response.data.status === 1) {
          setData(response.data.payload)
          setPage(1)
        }
        setLoading(false)
      })
    }
    feedbackFetch()
  }, [])

  const fetchFeedback = async () => {
    await api.get('/feedback', { params: { page } }).then((response) => {
      if (response.data.status === 1) {
        if (response.data.payload.length < 15) {
          setPaging(false)
        }
        setData((data) => [...data, ...response.data.payload])
        setPage(page + 1)
      }
      setLoading(false)
    })
  }

  const handleCancel = (e) => {
    setVisible(false)
  }

  // let currentUser = data[currentUser] || {};
  // currentUser = currentUser.user || {};
  return (
    <div className="animated fadeIn">
      <div>
        <div>
          <h2 style={{ display: 'inline-block' }}>Feedback</h2>
        </div>
        <hr />
        <Table
          pagination={false}
          style={{ backgroundColor: '#fff', padding: 10 }}
          columns={columns}
          dataSource={data}
          rowKey={'_id'}
          footer={() => (
            <div className="load-more-button">
              <Button disabled={!paging} loading={loading} onClick={fetchFeedback}>
                Load More
              </Button>
            </div>
          )}
        />
        <Modal
          title={`${currentUser.firstName} ${currentUser.lastName}`}
          visible={visible}
          onOk={handleCancel}
          onCancel={handleCancel}
        >
          <table className="table">
            <tbody>
              {Object.keys(currentUser).map((key) => {
                let value
                if (key === 'newsInterests') {
                  value = currentUser[key].join(',')
                }
                if (['avatar', 'followers', 'followings'].includes(key)) {
                  return
                }
                return (
                  <tr key={key}>
                    <td>{key}</td>
                    <td>{value || currentUser[key]}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Modal>
      </div>
    </div>
  )
}

export default Feedback
