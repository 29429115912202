import {
    QUIZ_FETCH_START,
    QUIZ_FETCH_SUCCESS,
    QUIZ_FETCH_FAILURE,
    QUIZ_ADD_SUCCESS,
} from '../action/constants';


const INITIAL_STATE = {
    loading : false,
    cat_loading:false,
    quizes:[],
    currentCategort:undefined,
    allCategories:[],
    errorMessage:undefined
}

const quizReducer = (state=INITIAL_STATE,action)=>{

    switch(action.type){
        case QUIZ_FETCH_START:
            return {
                ...state,
                loading:true
            }
            break;

        case QUIZ_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                quizes:action.payload
            }
            break;

        case QUIZ_FETCH_FAILURE:
            return {
                ...state,
                loading:false,
                errorMessage:action.payload
            }

        case QUIZ_ADD_SUCCESS:
            let newA = [...state.quizes]
            newA.push(action.payload)
            return {
                ...state,
                quizes:newA
            }

        default:
            return state
        
    }
}

export default quizReducer;