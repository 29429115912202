import produce from 'immer'
import { combineReducers } from 'redux'
import { POLL_RESULT_FETCH_SUCCESS } from '../action/constants'

const initialState = {
  byIds: {},
}

const idsReducer = produce((draft, action) => {
  if (action.type === POLL_RESULT_FETCH_SUCCESS) {
    draft[action.payload.poll._id] = action.payload
  }
}, initialState.byIds)

const pollResultReducer = combineReducers({
  byIds: idsReducer,
})

export default pollResultReducer
