import produce from 'immer'
import { combineReducers } from 'redux'
import {
  NEWS_ADD_SUCCESS,
  NEWS_DELETE_SUCCESS,
  NEWS_FETCH_FAILURE,
  NEWS_FETCH_START,
  NEWS_FETCH_SUCCESS,
  NEWS_LANGUAGE_SWITCH,
  NEWS_SINGLE_FETCH_SUCCESS,
  NEWS_UPDATE_SUCCESS,
} from '../action/constants'

const initialState = {
  byIds: {},
  news: {
    lastTime: undefined,
    loading: false,
    paging: true,
    data: [],
    hindi: false,
  },
}

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case NEWS_FETCH_SUCCESS:
      action.payload.payload.forEach((newsItem) => {
        draft[newsItem._id] = newsItem
      })
      break
    case NEWS_ADD_SUCCESS:
      draft[action.payload._id] = action.payload
      break
    case NEWS_DELETE_SUCCESS:
      delete draft[action.payload]
      break
    case NEWS_UPDATE_SUCCESS:
      let oldObj = draft[action.payload._id]
      draft[action.payload._id] = { ...action.payload, user: oldObj.user }
      break
    case NEWS_SINGLE_FETCH_SUCCESS:
      let newsItem = action.payload
      draft[newsItem._id] = newsItem
      break
  }
}, initialState.byIds)

const newsSubReducer = produce((draft, action) => {
  switch (action.type) {
    case NEWS_FETCH_START:
      draft.loading = true
      break
    case NEWS_LANGUAGE_SWITCH:
      draft.hindi = !draft.hindi
      draft.lastTime = undefined
      draft.data = []
      break
    case NEWS_FETCH_SUCCESS:
      let newNews = action.payload.payload
      if (newNews.length < 15) {
        draft.paging = false
      }
      newNews = newNews.map((newsItem) => newsItem._id)
      draft.loading = false
      draft.data.push(...newNews)
      draft.lastTime = action.payload.lastTime || draft.lastTime
      break
    case NEWS_FETCH_FAILURE:
      draft.loading = false
      draft.paging = false
      draft.paging = false
      break
    case NEWS_ADD_SUCCESS:
      draft.data.unshift(action.payload._id)
      break
    case NEWS_DELETE_SUCCESS:
      const index = draft.data.indexOf(action.payload)
      draft.data.splice(index, 1)
      break
  }
}, initialState.news)

const newsReducer = combineReducers({
  byIds: idsReducer,
  news: newsSubReducer,
})

export default newsReducer
