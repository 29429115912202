import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import './index.css'
import './scss/style.css'
import { useSelector } from 'react-redux'
import { fetchAllRegions } from './action/regionActions'
import Router from './routes'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Page500 = React.lazy(() => import('./views/Pages/Page500'))
const EditorNewsAddForm = React.lazy(() => import('./views/News/EditorNews/EditorNewsCreateForm'))
const CustomAudio = React.lazy(() => import('./views/News/CustomAudio'))

const App = () => {
  const regions = useSelector((state) => state.regions)
  useEffect(() => {
    if (regions.regions.data.length === 0) {
      fetchAllRegions()
    }
  }, [regions])

  return (
    <React.Suspense fallback={loading}>
      {/* <Routes>
          <Route path="/" element={ <DefaultLayout/>}>
          {routes.map((route, idx) => {
                    return route.element ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                          <route.element {...props} />
                        )} />
                    ) : (null);
          })}
            
          </Route>
          
        </Routes> */}
      <Router />
    </React.Suspense>
  )
}

export default App
