import axios from 'axios';

// Personal Server
// export const apiBaseUrl = 'http://localhost:2112/api/v1/';
// New Prod Server
// export const apiBaseUrl = 'http://13.234.125.150:2112/api/v1/';
// Production Server
export const apiBaseUrl = 'https://backend.polbol.in/backend';

export default axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('p-x-auth')}`;
      }
    }
  }
});
