import {
  QUESTION_FETCH_START,
  QUESTION_FETCH_SUCCESS,
  QUESTION_FETCH_FAILURE,
  QUESTION_ADD_SUCCESS,
  QUESTION_UPDATE_SUCCESS,
  QUESTION_ADD_START,
} from '../action/constants'

import { produce } from 'immer'

const INITIAL_STATE = {
  byIds: {},
  loading: false,
  paging: false,
  questions: [],
}

const questionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUESTION_FETCH_START:
      return produce(state, (draft) => {
        draft.loading = true
      })

    case QUESTION_FETCH_SUCCESS:
      return produce(state, (draft) => {
        action.payload.forEach((question) => {
          draft.byIds[question._id] = question
        })

        draft.questions = action.payload
      })

    case QUESTION_FETCH_FAILURE:
      return produce(state, (draft) => {
        draft.loading = false
      })
    case QUESTION_ADD_SUCCESS:
      return produce(state, (draft) => {
        draft.questions.push(action.payload)
        draft.byIds[action.payload._id] = action.payload
      })

    case QUESTION_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.byIds[action.payload._id] = action.payload
      })

    case QUESTION_ADD_START:
      return produce(state, (draft) => {})

    default:
      return state
  }
}

export default questionReducer
