import {
  USERS_FETCH_FAILURE,
  USERS_FETCH_START,
  USERS_FETCH_SUCCESS,
  USERS_ORDER_SWITCH,
  USERS_SEARCH_FAILURE,
  USERS_SEARCH_RESET,
  USERS_SEARCH_START,
  USERS_SEARCH_SUCCESS,
} from "../action/constants";
import produce from "immer";
import {combineReducers} from "redux";

const initialState = {
  byIds: {},
  users: {
    loading: false,
    lastTime: undefined,
    paging: true,
    data: [],
    latest: false,
  },
  search: {
    query: '',
    loading: false,
    lastTime: undefined,
    paging: true,
    data: [],
  }
};

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case USERS_FETCH_SUCCESS:
    case USERS_SEARCH_SUCCESS:
      action.payload.payload.forEach(newsItem => {
        draft[newsItem._id] = newsItem;
      });
      break;
  }
}, initialState.byIds);

const usersSubReducer = produce((draft, action) => {
  switch (action.type) {
    case USERS_FETCH_START:
      draft.loading = true;
      break;
    case USERS_ORDER_SWITCH:
      draft.latest = !draft.latest;
      draft.lastTime = undefined;
      draft.data = [];
      break;
    case USERS_FETCH_SUCCESS:
      let newUsers = action.payload.payload;
      if (newUsers.length < 100) {
        draft.paging = false;
      }
      newUsers = newUsers.map(usersItem => usersItem._id);
      draft.loading = false;
      draft.data.push(...newUsers);
      draft.lastTime = action.payload.lastTime;
      break;
    case USERS_FETCH_FAILURE:
      draft.loading = false;
      draft.paging = false;
      break;
  }
}, initialState.users);

const userSearchReducer = produce((draft, action) => {
  switch (action.type) {
    case USERS_SEARCH_RESET:
      draft.lastTime = undefined;
      draft.loading = false;
      draft.paging = true;
      draft.data = [];
      draft.query = action.payload;
      break;
    case USERS_SEARCH_START:
      draft.loading = true;
      break;
    case USERS_SEARCH_SUCCESS:
      let newUsers = action.payload.payload;
      if (newUsers.length < action.payload.maxCount) {
        draft.paging = false;
      }
      newUsers = newUsers.map(usersItem => usersItem._id);
      draft.loading = false;
      draft.data.push(...newUsers);
      draft.lastTime = action.payload.lastTime;
      break;
    case USERS_SEARCH_FAILURE:
      draft.loading = false;
      draft.paging = false;
      break;
  }
}, initialState.search);


const usersReducer = combineReducers({
  byIds: idsReducer,
  users: usersSubReducer,
  search: userSearchReducer,
});

export default usersReducer;
