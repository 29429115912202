import {
  CHANNEL_FETCH_FAILURE,
  CHANNEL_FETCH_START,
  CHANNEL_FETCH_SUCCESS,
  CHANNEL_ADD_SUCCESS,
  CHANNEL_UPDATE_SUCCESS,
  CHANNEL_SINGLE_FETCH_SUCCESS,
} from "../action/constants";
import produce from "immer";
import { combineReducers } from "redux";

const initialState = {
  byIds: {},
  state: {
    loading: false,
    data: [],
  },
};

const idChannelReducer = produce((draft, action) => {
  switch (action.type) {
    case CHANNEL_FETCH_SUCCESS:
      action.payload.forEach((item) => {
        draft[item._id] = item;
      });
      break;
    case CHANNEL_SINGLE_FETCH_SUCCESS:
      draft[action.payload._id] = action.payload;
      break;

    case CHANNEL_ADD_SUCCESS:
      draft[action.payload._id] = action.payload;
      break;

    case CHANNEL_UPDATE_SUCCESS:
      draft[action.payload._id] = action.payload;
      break;
  }
}, initialState.byIds);

const channelSubReducer = produce((draft, action) => {
  let newData;
  switch (action.type) {
    case CHANNEL_FETCH_START:
      draft.loading = true;
      break;
    case CHANNEL_ADD_SUCCESS:
       newData = action.payload.data;
      draft.data = draft.data.splice(0, 0, newData);

      break;
    case CHANNEL_FETCH_SUCCESS:
       newData = action.payload;
      if (newData.length < 15) {
      }
      newData = newData.map((dataItem) => dataItem._id);
      draft.loading = false;
      draft.data.push(...newData);

      break;
    case CHANNEL_FETCH_FAILURE:
      draft.loading = false;

      break;
  }
}, initialState.state);

const channelsReducer = combineReducers({
  byIds: idChannelReducer,
  state: channelSubReducer,
});

export default channelsReducer;
