import produce from 'immer'
import { combineReducers } from 'redux'
import { REGION_FETCH_FAILURE, REGION_FETCH_START, REGION_FETCH_SUCCESS } from '../action/constants'

const initialState = {
  byIds: {},
  regions: {
    loading: false,
    data: [],
  },
}

const idsReducer = produce((draft, action) => {
  if (action.type === REGION_FETCH_SUCCESS) {
    action.payload.forEach((regionItem) => {
      draft[regionItem._id] = regionItem
    })
  }
}, initialState.byIds)

const regionsSubReducer = produce((draft, action) => {
  switch (action.type) {
    case REGION_FETCH_START:
      draft.loading = true
      break
    case REGION_FETCH_SUCCESS:
      let regions = action.payload
      regions = regions.map((regionItem) => regionItem._id)
      draft.loading = false
      draft.data.push(...regions)
      break
    case REGION_FETCH_FAILURE:
      draft.loading = false
      break
  }
}, initialState.regions)

const regionsReducer = combineReducers({
  byIds: idsReducer,
  regions: regionsSubReducer,
})

export default regionsReducer
