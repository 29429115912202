const INIT_STATE = {
  allUsers: [],
  allPosts: [],
  allPolls: [],
  allParty: [],
  allMedia: [],
  allCompetition: [],
  allSubmission: [],
  allSpecialization: [],
  verifyPhone: undefined
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ALL_USER":
      return {...state, allUsers: action.payload};

    case "ALL_PARTY":
      return {...state, allParty: action.payload};

    case "ALL_MEDIA":
      return {...state, allMedia: action.payload};

    case "VERIFY_PHONE":
      return {...state, verifyPhone: action.payload};

    case "ALL_COMPETITION":
      return {...state, allCompetition: action.payload};

    case "ALL_SUBMISSION":
      return {...state, allSubmission: action.payload};

    case "ALL_SPECIALIZATION":
      return {...state, allSpecialization: action.payload};

    default:
      return state;
  }

}
