import produce from "immer";
import {combineReducers} from "redux";
import {
  VIDEO_ADD_SUCCESS,
  VIDEO_DELETE_SUCCESS,
  VIDEO_FETCH_FAILURE,
  VIDEO_FETCH_START,
  VIDEO_FETCH_SUCCESS,
  VIDEO_LANGUAGE_SWITCH, VIDEO_SINGLE_FETCH_SUCCESS,
  VIDEO_UPDATE_SUCCESS
} from "../action/constants";

const initialState = {
  byIds: {},
  video: {
    lastTime: undefined,
    loading: false,
    paging: true,
    data: [],
    hindi: false
  }
};

const idsReducer = produce((draft, action) => {
  switch (action.type) {
    case VIDEO_FETCH_SUCCESS:
      action.payload.payload.forEach(videoItem => {
        draft[videoItem._id] = videoItem;
      });
      break;
    case VIDEO_ADD_SUCCESS:
      draft[action.payload._id] = action.payload;
      break;
    case VIDEO_DELETE_SUCCESS:
      delete draft[action.payload];
      break;
    case VIDEO_UPDATE_SUCCESS:
      let oldObj = draft[action.payload._id];
      draft[action.payload._id] = {...action.payload, user: oldObj.user};
      break;
    case VIDEO_SINGLE_FETCH_SUCCESS:
      let videoItem = action.payload;
      draft[videoItem._id] = videoItem;
      break;
  }
}, initialState.byIds);

const videoSubReducer = produce((draft, action) => {
  switch (action.type) {
    case VIDEO_FETCH_START:
      draft.loading = true;
      break;
    case VIDEO_LANGUAGE_SWITCH:
      draft.hindi = !draft.hindi;
      draft.lastTime = undefined;
      draft.data = [];
      break;
    case VIDEO_FETCH_SUCCESS:
      let newVideos = action.payload.payload;
      if (newVideos.length < action.payload.maxCount) {
        draft.paging = false;
      }
      newVideos = newVideos.map(videoItem => videoItem._id);
      draft.loading = false;
      draft.data.push(...newVideos);
      draft.lastTime = action.payload.lastTime || draft.lastTime;
      break;
    case VIDEO_FETCH_FAILURE:
      draft.loading = false;
      draft.paging = false;
      draft.paging = false;
      break;
    case VIDEO_ADD_SUCCESS:
      draft.data.unshift(action.payload._id);
      break;
    case VIDEO_DELETE_SUCCESS:
      const index = draft.data.indexOf(action.payload);
      draft.data.splice(index, 1);
      break;
  }
}, initialState.video);

const videosReducer = combineReducers({
  byIds: idsReducer,
  video: videoSubReducer,
});

export default videosReducer;
