import api from '../api'
import { REGION_FETCH_FAILURE, REGION_FETCH_START, REGION_FETCH_SUCCESS } from './constants'

export const fetchAllRegions = () => {
  return async (dispatch, getState) => {
    dispatch(regionFetchStart())
    try {
      let response = null

      response = await api.get(`/region`)

      const responseJson = response.data
      //
      if (responseJson.status === 1) {
        dispatch(regionFetchSuccess(responseJson.payload))
      } else {
        //
        dispatch(regionFetchError())
      }
    } catch (e) {
      dispatch(regionFetchError())
    }
  }
}

const regionFetchStart = () => {
  return {
    type: REGION_FETCH_START,
  }
}

const regionFetchSuccess = (data) => {
  return {
    type: REGION_FETCH_SUCCESS,
    payload: data,
  }
}

const regionFetchError = () => {
  return {
    type: REGION_FETCH_FAILURE,
  }
}
