export const NEWS_FETCH_START = 'NEWS_FETCH_START';
export const NEWS_FETCH_FAILURE = 'NEWS_FETCH_FAILURE';
export const NEWS_FETCH_SUCCESS = 'NEWS_FETCH_SUCCESS';
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const NEWS_ADD_SUCCESS = 'NEWS_ADD_SUCCESS';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_LANGUAGE_SWITCH = 'NEWS_LANGUAGE_SWITCH';
export const NEWS_SINGLE_FETCH_SUCCESS = 'NEWS_SINGLE_FETCH_SUCCESS';


export const EDITOR_NEWS_FETCH_START = 'EDITOR_NEWS_FETCH_START';
export const EDITOR_NEWS_FETCH_FAILURE = 'EDITOR_NEWS_FETCH_FAILURE';
export const EDITOR_NEWS_FETCH_SUCCESS = 'EDITOR_NEWS_FETCH_SUCCESS';
export const EDITOR_NEWS_REMOVE_FROM_LIST = 'EDITOR_NEWS_REMOVE_FROM_LIST';
export const EDITOR_NEWS_UPDATE_SUCCESS = 'EDITOR_NEWS_UPDATE_SUCCESS';
export const EDITOR_NEWS_LANGUAGE_SWITCH = 'EDITOR_NEWS_LANGUAGE_SWITCH';

export const VIDEO_FETCH_START = 'VIDEO_FETCH_START';
export const VIDEO_FETCH_FAILURE = 'VIDEO_FETCH_FAILURE';
export const VIDEO_FETCH_SUCCESS = 'VIDEO_FETCH_SUCCESS';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_ADD_SUCCESS = 'VIDEO_ADD_SUCCESS';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_LANGUAGE_SWITCH = 'VIDEO_LANGUAGE_SWITCH';
export const VIDEO_SINGLE_FETCH_SUCCESS = 'NEWS_SINGLE_FETCH_SUCCESS';

export const REGION_FETCH_START = 'REGION_FETCH_START';
export const REGION_FETCH_FAILURE = 'REGION_FETCH_FAILURE';
export const REGION_FETCH_SUCCESS = 'REGION_FETCH_SUCCESS';

export const USERS_FETCH_START = 'USERS_FETCH_START';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE';
export const USERS_ORDER_SWITCH = 'USERS_ORDER_SWITCH';

export const USERS_SEARCH_RESET = 'USERS_SEARCH_RESET';
export const USERS_SEARCH_START = 'USERS_SEARCH_START';
export const USERS_SEARCH_SUCCESS = 'USERS_SEARCH_SUCCESS';
export const USERS_SEARCH_FAILURE = 'USERS_SEARCH_FAILURE';




export const POLL_FETCH_START = 'POLL_FETCH_START';
export const POLL_FETCH_FAILURE = 'POLL_FETCH_FAILURE';
export const POLL_FETCH_SUCCESS = 'POLL_FETCH_SUCCESS';
export const POLL_PAGE_FETCH_SUCCESS = 'POLL_PAGE_FETCH_SUCCESS';
export const POLL_ADD_SUCCESS = 'POLL_ADD_SUCCESS';
export const POLL_UPDATE_SUCCESS = 'POLL_UPDATE_SUCCESS';

export const POLL_RESULT_FETCH_SUCCESS = 'POLL_RESULT_FETCH_SUCCESS';

export const AWARD_FETCH_START = 'AWARD_FETCH_START';
export const AWARD_FETCH_FAILURE = 'AWARD_FETCH_FAILURE';
export const AWARD_FETCH_SUCCESS = 'AWARD_FETCH_SUCCESS';
export const AWARD_ADD_SUCCESS = 'AWARD_ADD_SUCCESS';
export const AWARD_UPDATE_SUCCESS = 'AWARD_UPDATE_SUCCESS';

export const AWARD_RESULT_FETCH_SUCCESS = 'AWARD_RESULT_FETCH_SUCCESS';

export const POST_FETCH_START = 'POST_FETCH_START';
export const POST_FETCH_FAILURE = 'POST_FETCH_FAILURE';
export const POST_FETCH_SUCCESS = 'POST_FETCH_SUCCESS';
export const POST_ADD_SUCCESS = 'POST_ADD_SUCCESS';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';

export const CHANNEL_FETCH_START = 'CHANNEL_FETCH_START';
export const CHANNEL_FETCH_FAILURE = 'CHANNEL_FETCH_FAILURE';
export const CHANNEL_FETCH_SUCCESS = 'CHANNEL_FETCH_SUCCESS';
export const CHANNEL_ADD_SUCCESS = 'CHANNEL_ADD_SUCCESS';
export const CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS';
export const CHANNEL_DELETE_SUCCESS = 'CHANNEL_DELETE_SUCCESS';
export const CHANNEL_SINGLE_FETCH_SUCCESS = 'CHANNEL_SINGLE_FETCH_SUCCESS';





/*-------------------code by Abhinav Gautam(11/12/2020)------------------*/


export const QUIZ_FETCH_START = 'QUIZ_FETCH_START';
export const QUIZ_FETCH_FAILURE = 'QUIZ_FETCH_FAILURE';
export const QUIZ_FETCH_SUCCESS = 'QUIZ_FETCH_SUCCESS';
export const QUIZ_ADD_SUCCESS = 'QUIZ_ADD_SUCCESS';
export const QUIZ_UPDATE_SUCCESS = 'QUIZ_UPDATE_SUCCESS';


export const CATEGORIES_FETCH_START = 'CATEGORIES_FETCH_START';
export const CATEGORIES_FETCH_FAILURE = 'CATEGORIES_FETCH_FAILURE';
export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS';
export const CATEGORIES_ADD_SUCCESS = 'CATEGORIES_ADD_SUCCESS';
export const CATEGORIES_UPDATE_SUCCESS = 'CATEGORIES_UPDATE_SUCCESS';


export const QUESTION_FETCH_START = 'QUESTION_FETCH_START';
export const QUESTION_FETCH_FAILURE = 'QUESTION_FETCH_FAILURE';
export const QUESTION_FETCH_SUCCESS = 'QUESTION_FETCH_SUCCESS';
export const QUESTION_ADD_SUCCESS = 'QUESTION_ADD_SUCCESS';
export const QUESTION_UPDATE_SUCCESS = 'QUESTION_UPDATE_SUCCESS';
export const QUESTION_ADD_START='QUESTION_ADD_START';


export const SHOW_FETCH_START = 'SHOW_FETCH_START';
export const SHOW_FETCH_SUCCESS = 'SHOW_FETCH_SUCCESS';
export const SHOW_FETCH_FAILURE = 'SHOW_FETCH_FAILURE';
export const SHOW_ADD_START = 'SHOW_ADD_START';
export const SHOW_ADD_SUCCESS = 'SHOW_ADD_SUCCESS';
export const SHOW_ADD_FAILURE = 'SHOW_ADD_FAILURE';
export const SHOW_UPDATE_START = 'SHOW_UPDATE_START';
export const SHOW_UPDATE_SUCCESS = 'SHOW_UPDATE_SUCCESS';
export const SHOW_UPDATE_FAILURE = 'SHOW_UPDATE_FAILURE';

/*-------------------code by Nikhil Tale(14/12/2021)------------------*/



export const PETITION_FETCH_START = 'PETITION_FETCH_START';
export const PETITION_FETCH_FAILURE = 'PETITION_FETCH_FAILURE';
export const PETITION_FETCH_SUCCESS = 'PETITION_FETCH_SUCCESS';
export const PETITION_UPDATE_SUCCESS = 'PETITION_UPDATE_SUCCESS';
export const PETITION_ADD_SUCCESS = 'PETITION_ADD_SUCCESS';
export const PETITION_DELETE_SUCCESS = 'PETITION_DELETE_SUCCESS';
export const PETITION_SINGLE_FETCH_SUCCESS = 'PETITION_SINGLE_FETCH_SUCCESS';


export const EDITOR_PETITION_FETCH_START = 'EDITOR_PETITION_FETCH_START';
export const EDITOR_PETITION_FETCH_FAILURE = 'EDITOR_PETITION_FETCH_FAILURE';
export const EDITOR_PETITION_FETCH_SUCCESS = 'EDITOR_PETITION_FETCH_SUCCESS';
export const EDITOR_PETITION_REMOVE_FROM_LIST = 'EDITOR_PETITION_REMOVE_FROM_LIST';
export const EDITOR_PETITION_UPDATE_SUCCESS = 'EDITOR_PETITION_UPDATE_SUCCESS';
export const EDITOR_PETITION_LANGUAGE_SWITCH = 'EDITOR_PETITION_LANGUAGE_SWITCH';