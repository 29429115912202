import {
    CATEGORIES_FETCH_START,
    CATEGORIES_FETCH_SUCCESS,
    CATEGORIES_FETCH_FAILURE,
    CATEGORIES_ADD_SUCCESS
    
    } from '../action/constants';



const INITIAL_STATE = {
    loading : false,
    allCategories:[],
    errorMessage:undefined
}

const quizCategoriesReducer = (state=INITIAL_STATE,action)=>{

    switch(action.type){
        case CATEGORIES_FETCH_START:
            return {
                ...state,
                loading:true
            }

        case CATEGORIES_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                allCategories:action.payload
            }
        case CATEGORIES_FETCH_FAILURE:
            return {
                ...state,
                loading:false,
                errorMessage:action.payload
            }
        case CATEGORIES_ADD_SUCCESS:
            let newA = [...state.allCategories]
            newA.push(action.payload)
            return {
                ...state,
                allCategories:newA
            }
            
        default:
            return state
        
    }
}

export default quizCategoriesReducer;